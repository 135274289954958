import React from 'react'
import TimeLine from '../../components/timeline'

import { ExpWrapper } from './style'

const Experiencias = ()=>{
    return(
        <ExpWrapper>
          <TimeLine/>
        </ExpWrapper>
    )
}
export default Experiencias